import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {HttpClientModule} from '@angular/common/http';
import { fakeBackendProvider } from './_helpers';
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { RepairPlanComponent } from '@/repair-plan/repair-plan.component';
import { StartComponent } from '@/start/start.component';
import { DriverComponent } from '@/driver/driver.component';
import { DirectorComponent } from '@/director/director.component';
import { IssueComponent } from '@/repair-plan/issue/issue.component';
import {PageNotFoundComponent} from '@/shared/components/page-not-found/page-not-found.component';
import {ModalDialogComponent} from '@/shared/components/modal-dialog/modal-dialog.component';
import {HeaderComponent} from '@/shared/components/header/header.component';
import {AsideComponent} from '@/shared/components/aside/aside.component';


// import { JwtInterceptor, ErrorInterceptor } from './_helpers';

@NgModule({
  declarations: [
    AppComponent,
    RepairPlanComponent,
    StartComponent,
    DriverComponent,
    DirectorComponent,
    IssueComponent,
    AsideComponent,
    HeaderComponent,
    ModalDialogComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MomentModule.forRoot({ relativeTimeThresholdOptions: { m: 59 } })
  ],
  providers: [
    // {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {StartComponent} from './start/start.component';
import {RepairPlanComponent} from './repair-plan/repair-plan.component';
import {DriverComponent} from './driver/driver.component';
import {DirectorComponent} from './director/director.component';
import {IssueComponent} from './repair-plan/issue/issue.component';

const routes: Routes = [
  {
    path: '',
    component: StartComponent

  },
  {
    path: 'master',
    component: RepairPlanComponent,
    children: [
      {path: 'issue/:id', component: IssueComponent}
    ]
  },
  {
    path: 'driver',
    component: DriverComponent
  },
  {
    path: 'director',
    component: DirectorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { IssueService } from '@/_services/issue.service';

@Component({
  selector: 'app-repair-plan',
  templateUrl: './repair-plan.component.html',
  styleUrls: ['./repair-plan.component.less']
})
export class RepairPlanComponent implements OnInit {

  issues = [];
  dateNow;
  sortBy;
  constructor(private data: IssueService) {
    this.sortBy = (property) => {
      let sortOrder = 1;
      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }
      return (a, b) => {
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
      };
    };
  }

  ngOnInit() {
    this.dateNow = new Date();
    this.data.getAll().subscribe(data => {
        this.issues = data.sort(this.sortBy('stars'));
      }
    );
  }



  seeMore(id) {
    this.issues[id].closed = !this.issues[id].closed;
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./start.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./start.component";
var styles_StartComponent = [i0.styles];
var RenderType_StartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartComponent, data: {} });
export { RenderType_StartComponent as RenderType_StartComponent };
export function View_StartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412\u044B\u0431\u0435\u0440\u0435\u0442\u0435 \u0440\u043E\u043B\u044C"])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "start-buttons-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, ["\u0412\u043E\u0434\u0438\u0442\u0435\u043B\u044C"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["\u041C\u0430\u0441\u0442\u0435\u0440"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, ["\u0420\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044C"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 6, 0, "/driver"); _ck(_v, 5, 0, currVal_0); var currVal_1 = _ck(_v, 10, 0, "/master"); _ck(_v, 9, 0, currVal_1); var currVal_2 = _ck(_v, 14, 0, "/director"); _ck(_v, 13, 0, currVal_2); }, null); }
export function View_StartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-start", [], null, null, null, View_StartComponent_0, RenderType_StartComponent)), i1.ɵdid(1, 114688, null, 0, i3.StartComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartComponentNgFactory = i1.ɵccf("app-start", i3.StartComponent, View_StartComponent_Host_0, {}, {}, []);
export { StartComponentNgFactory as StartComponentNgFactory };

<div class="repair-plan">
  <h1>План ремонта TC</h1>
  <div class="navigation" [routerLink]="['/']">Мастер</div>
  <div class="plan-list">
    <div class="plan-list__title">
      <button class="today"><img src="/assets/icon-calendar.png"></button> <span>{{dateNow | amDateFormat:'DD.MM.YYYY'}}</span>
      <span class="issues-amount"> 0 из {{issues.length || 0}}</span>
    </div>
    <div class="plan-list__list">
      <div class="issue-item" *ngFor="let issue of issues; index as i">
        <div class="issue-header" (click)="seeMore(i)">
          <button class="issue-number">{{i+1}}</button>
          <div class="issue-title">
            <div>{{issue.title}}</div>
            <div>{{issue.number}}</div>
          </div>
          <div class="issue-prioritet">
            <div class="label">приоритет</div>
            <div class="stars"  [attr.data-prioritet]="issue.stars">
              <span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span>
            </div>
          </div>
        </div>
        <div class="issue-description" [hidden]="issue.closed">
          <img src="/assets/bmw-classics-1640f7e.jpg">
          <ul>
            <li *ngFor="let task of issue.tasks; index as i"><span>{{i+1}}</span>{{task}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>

import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var IssueService = /** @class */ (function () {
    function IssueService(http) {
        this.http = http;
    }
    IssueService.prototype.getAll = function () {
        console.log("called issues");
        return this.http.get("/issues");
    };
    IssueService.ngInjectableDef = i0.defineInjectable({ factory: function IssueService_Factory() { return new IssueService(i0.inject(i1.HttpClient)); }, token: IssueService, providedIn: "root" });
    return IssueService;
}());
export { IssueService };

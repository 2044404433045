﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Issue } from '@/_models';

@Injectable({ providedIn: 'root' })
export class IssueService {
    constructor(private http: HttpClient) { }

    getAll() {
      console.log("called issues");
      return this.http.get<Issue[]>(`/issues`);
    }
}
